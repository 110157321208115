/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  outline: none;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@font-face {
  font-family: 'exo-light';
  src: url('./fonts/Exo-Light.ttf');
}
@font-face {
  font-family: 'exo-regular';
  src: url('./fonts/Exo-Regular.ttf');
}

@font-face {
  font-family: 'exo-italic';
  src: url('./fonts/Exo-Regular-Italic.otf');
}

@font-face {
  font-family: 'exo-extralight';
  src: url('./fonts/Exo-ExtraLight.ttf');
}
@font-face {
  font-family: 'exo-semibold';
  src: url('./fonts/Exo-SemiBold.ttf');
}
@font-face {
  font-family: 'roboto-medium';
  src: url('./fonts/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'roboto-bold';
  src: url('./fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'roboto-regular';
  src: url('./fonts/Roboto-Regular.ttf');
}

body {
  background-color: #fff;
}

.app-container {
  display: grid;
  grid-template-rows: 80px 1fr;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1281px) {
  .app-container {
    max-width: 1024px;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .app-container {
    max-width: 1024px;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .app-container {
    max-width: 960px;
  }
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.async-select-container {
  font-family: 'exo-regular';
  border-radius: 0px;
  border: 2px solid #dee7ee;
  min-height: 44px;
  background: white;
}

.async-select-container:focus-within {
  border: 2px solid #0abde3;
}

.async-select > div {
  margin-top: -7px;
  background: transparent;
}
.async-select > div:nth-child(3) {
  background: white;
  border-radius: 0px;
}

.async-select > div > div:nth-child(2) {
  min-height: 44px;
  margin-top: -10px;
}

.async-select-container > .label-container {
  margin-left: 10px;
}

.label-container {
  font-family: 'exo-light';
  font-size: 11px;
  font-style: normal;
  font-stretch: normal;
  line-height: 15px;
  -webkit-letter-spacing: 0.02em;
  -moz-letter-spacing: 0.02em;
  -ms-letter-spacing: 0.02em;
  letter-spacing: 0.02em;
  color: #7a838f;
}

.ql-editor {
  min-height: 100px;
  max-height: 100px;
}

.quill {
  position: relative;
  max-width: 895px;
}

a {
  color: #00a8ff;
  text-decoration: none;
  font-family: 'exo-light';
}

a:visited {
  text-decoration: none;
}
